// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./turbo_streams"

// Needed for interactive behaviour like navbar
import "flowbite/dist/flowbite.turbo.js";
import "./darkener";
import "./sidebar";
import posthog from 'posthog-js'

document.addEventListener("turbo:load", () => {
  console.log("Current URL : ", window.location.href);
  posthog.init('phc_EBJkXlOYZ0k1UyqBsk7wAJGSs9EJxK43ZF8rD63Zfpn',
      {
          api_host: 'https://eu.i.posthog.com',
          person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
      }
  )
});



